// Static imports
import newgrad from '../images/newgrad.webp';
import lowegarden from '../images/lowegarden.webp';
import motionGraph from '../images/digit-motion.gif';
import fourks from '../images/4ks.webp';

export default [

  {
    src: 'https://youtube.com/playlist?list=PLEB__KUjDwLNfXHNTU32ZfiakE0T67fa6',
    name: 'Motion Graphics Playlist',
    desc: 'Professional motion graphics/videography work for Digit (Oportun), Oakland Raiders, and Google Fiber',
    image: motionGraph,
    newTab: true,
    tabSrc: 'https://youtube.com/playlist?list=PLEB__KUjDwLNfXHNTU32ZfiakE0T67fa6',
  },
  {
    src: 'https://www.youtube.com/embed/mxGLk7luIiA?list=PLIPRDs7WMkBf1rUoNsHBtxmWynh3uB5Qh&rel=0&modestbranding=1&autohide=1&showinfo=0&autoplay=1',
    name: '4KS Weddings',
    desc: 'sony a1, sony fs5, in collaboration with 4ks',
    image: fourks,
  },
  {
    src: 'https://www.youtube.com/embed/UwcrPIqQJOE?rel=0&modestbranding=1&autohide=1&showinfo=0&autoplay=1',
    name: 'New Grad (2020)',
    desc: 'sony fs5, sirui 50mm anamorphic',
    image: newgrad,
  },
  {
    src: 'https://www.youtube.com/embed/BVXAQADq5go?rel=0&modestbranding=1&autohide=1&showinfo=0',
    name: 'Lowe Garden (2020)',
    desc: 'sony fs5, samyang 85mm, 2x isco ultrastar anamorphic',
    image: lowegarden,
    default: true,
    anamorphic: true,
  },
];
