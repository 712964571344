import React, { useEffect, useRef } from 'react';
import styled, { keyframes } from 'styled-components';
import { animate, scroll, inView } from 'motion';
import PropTypes from 'prop-types';

import floatingWenzel from '../images/self.webp';
import bg from '../images/bg.webp';

const BGImage = styled.div`
  background-image: url(${bg});
`;

const float = keyframes`
  0% {
    transform: translatey(0px);
  }
  50% {
    transform: translatey(-20px);
  }
  100% {
    transform: translatey(0px);
  }
`;

const FloatingWenzel = styled.img`
  min-width: 37rem;
  animation: ${float} 6s ease-in-out infinite;
`;

const HighlightedH3 = styled.h3.attrs({
  className: 'text-xl xl:text-2xl  ml-6 mb-6 uppercase tracking-even-wider whitespace-nowrap',
})`
  position: relative;
  display: inline-block;
  cursor: pointer;
  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: -1.5rem;
    right: 0;
    top: 65%;
    background: ${(p) => p.colorOne};
    opacity: 0.2;
    z-index: -1;
    transition: 0.15s;
  }
  &:hover:before {
    background: ${(p) => p.colorTwo};
    opacity: 1;
    right: -3rem;
    top: 55%;
  }
`;

const HeroTitle = ({
  scrollToSoftware,
  scrollToVideography,
}) => {
  const containerRef = useRef(null);
  const contentRef = useRef(null);
  const softwareLinkRef = useRef(null);
  const videoLinkRef = useRef(null);
  const floatingImageRef = useRef(null);

  useEffect(() => {
    // Scroll animation
    scroll(
      ({ y }) => {
        if (containerRef.current) {
          containerRef.current.style.transform = `translateY(${y.progress * -150}px)`;
          containerRef.current.style.opacity = 1 - (y.progress * 0.8);
        }
      }
    );

    // Initial animations
    if (contentRef.current) {
      animate(
        contentRef.current,
        { y: [100, 0], opacity: [0, 1] },
        { duration: 0.5 }
      );
    }

    // Software link animation
    if (softwareLinkRef.current) {
      animate(
        softwareLinkRef.current,
        { x: [-150, 0], opacity: [0, 1] },
        { duration: 1, delay: 0.75 }
      );

      softwareLinkRef.current.addEventListener('mouseenter', () => {
        animate(softwareLinkRef.current, { scale: 1.1, rotate: -1 }, { duration: 0.2 });
      });

      softwareLinkRef.current.addEventListener('mouseleave', () => {
        animate(softwareLinkRef.current, { scale: 1, rotate: 0 }, { duration: 0.2 });
      });
    }

    // Videography link animation
    if (videoLinkRef.current) {
      animate(
        videoLinkRef.current,
        { x: [-150, 0], opacity: [0, 1] },
        { duration: 1, delay: 1.15 }
      );

      videoLinkRef.current.addEventListener('mouseenter', () => {
        animate(videoLinkRef.current, { scale: 1.1, rotate: -1 }, { duration: 0.2 });
      });

      videoLinkRef.current.addEventListener('mouseleave', () => {
        animate(videoLinkRef.current, { scale: 1, rotate: 0 }, { duration: 0.2 });
      });
    }

    // Floating animation
    if (floatingImageRef.current) {
      animate(
        floatingImageRef.current,
        { y: [-20, 0] },
        { duration: 1, repeat: Infinity, direction: "alternate" }
      );
    }
  }, []);

  return (
    <div ref={containerRef} className="hero-container flex flex-col items-center justify-center min-h-screen">
      <BGImage className="hidden xl:block w-2/6 absolute top-0 right-0 bottom-16 bg-right bg-no-repeat bg-cover z-0" />

      <div className="flex flex-col justify-center xl:justify-start xl:grid xl:grid-cols-2 w-full h-full flex-1">
        <div className="container flex items-center w-full h-full">
          <div ref={contentRef} className="hero-content xl:pl-48 lg:pl-36 px-12 z-20">
            <h1 className="text-5xl lg:text-6xl xl:text-7.5xl font-display font-bold mb-8">
              Hi, I&apos;m Wenzel Lowe
            </h1>
            <HighlightedH3
              ref={softwareLinkRef}
              className="software-link"
              onClick={scrollToSoftware}
              colorOne="#395c6b"
              colorTwo="#39a0ed"
            >
              software engineer
            </HighlightedH3>
            <br />
            <HighlightedH3
              ref={videoLinkRef}
              className="video-link"
              onClick={scrollToVideography}
              colorOne="#9C88FF"
              colorTwo="#9C88FF"
            >
              videographer
            </HighlightedH3>
            <br />
          </div>
        </div>
        <div className="hidden xl:flex z-10 p-12 mt-24 pl-48 justify-center align-center">
          <div ref={floatingImageRef} className="floating-image">
            <FloatingWenzel src={floatingWenzel} />
          </div>
        </div>
      </div>
    </div>
  );
};

HeroTitle.propTypes = {
  scrollToSoftware: PropTypes.func.isRequired,
  scrollToVideography: PropTypes.func.isRequired,
};

export default HeroTitle;
