import React, { useContext, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { animate } from 'motion';
import {
  Instagram, GitHub, Linkedin, FileText,
} from 'react-feather';
import ThemeContext from '../context/ThemeContext';

const IconContainer = styled.a.attrs({
  className: `h-4 xl:h-6 mb-4`,
})`
  cursor: pointer;
`;

const BorderedDiv = styled.div``;

const SocialLinks = () => {
  const { dark: [hasDark] } = useContext(ThemeContext);
  const iconsRef = useRef([]);

  useEffect(() => {
    iconsRef.current.forEach(icon => {
      if (icon) {
        icon.addEventListener('mouseenter', () => {
          animate(icon, { scale: 1.2, rotate: -10 }, { duration: 0.2 });
        });

        icon.addEventListener('mouseleave', () => {
          animate(icon, { scale: 1, rotate: 0 }, { duration: 0.2 });
        });
      }
    });
  }, []);

  return (
    <div className="hidden xl:fixed left-12 xl:left-24 bottom-4 xl:flex flex-col-reverse z-50">
      <BorderedDiv className={`duration-1000 h-12 xl:h-24 w-px border-r ${hasDark ? 'border-grey-600' : 'border-primary'} self-center`} />
      <IconContainer
        ref={el => iconsRef.current[0] = el}
        href="https://instagram.com/wnzls"
        target="_blank"
        className={`social-icon h-4 xl:h-6 mb-4 ${hasDark ? 'text-white' : 'color-primary'}`}
      >
        <Instagram className="stroke-1 w-4 xl:w-6" />
      </IconContainer>
      <IconContainer
        ref={el => iconsRef.current[1] = el}
        href="https://github.com/lowewenzel"
        target="_blank"
        className={`social-icon h-4 xl:h-6 mb-4 ${hasDark ? 'text-white' : 'color-primary'}`}
      >
        <GitHub className="stroke-1 w-4 xl:w-6" />
      </IconContainer>
      <IconContainer
        ref={el => iconsRef.current[2] = el}
        href="https://linkedin.com/in/wnzl"
        target="_blank"
        className={`social-icon h-4 xl:h-6 mb-4 ${hasDark ? 'text-white' : 'color-primary'}`}
      >
        <Linkedin className="stroke-1 w-4 xl:w-6" />
      </IconContainer>
      <IconContainer
        ref={el => iconsRef.current[3] = el}
        href="mailto:hi@wnzl.dev"
        target="_blank"
        className={`social-icon h-4 xl:h-6 mb-4 ${hasDark ? 'text-white' : 'color-primary'}`}
      >
        <FileText className="stroke-1 w-4 xl:w-6" />
      </IconContainer>
    </div>
  );
};

export default SocialLinks;
