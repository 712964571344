import React, { useEffect, useRef } from 'react';
import styled, { keyframes } from 'styled-components';
import { animate, scroll, inView } from 'motion';
import WenzelFilled from '../images/logo-filled.svg';
import WenzelOutline from '../images/logo-empty.svg';

const WENZEL_MARGIN = 180;

const SUMMARY_TEXT = 'i am a software engineer with previous work in motion graphics. i also am a videographer at heart, capturing stories and moments in motion.';

const WenzelTitle = styled.h1.attrs({ className: 'text-5xl lg:text-6xl xl:text-7.5xl whitespace-nowrap font-display font-bold text-right md:text-left' })``;

const float = keyframes`
  0% {
    transform: translatey(0px) skewY(-20deg);
  }
  50% {
    transform: translatey(-40px) skewY(-20deg);
  }
  100% {
    transform: translatey(0px) skewY(-20deg);
  }
`;

const Polygon = styled.div`
  position: absolute;
  left: 0px;
  width: 100%;
  height: 100%;
  transform: skewY(-20deg);
  background: DDE2E1;
  animation: ${float} 8s ease-in-out infinite;
`;

const StripedPolygon = styled(Polygon)`
  position: absolute;
  left: 0px;
  top: 0px;
  background: initial;
  transform: skewY(-20deg) translateY(40px);
  border: 2px dashed #707070;
  animation: ${float} 10s ease-in-out infinite;
`;

const Polygons = () => (
  <div className="z-0 absolute w-2/6 h-full left-0">
    <StripedPolygon />
  </div>
);

const WenzelsContainer = styled.div`
  position: relative;
  &:before {
    content: 'art by jaerica';
    text-transform: uppercase;
    font-size: 0.5rem;
    position: absolute;
    bottom: -20px;
    margin-right: 144px;
    color: #333;
  }
`;

const LeftWenzel = styled.img`
  margin-right: -${WENZEL_MARGIN}px;
  cursor: wait;
`;

const CenterWenzel = styled.img`
  z-index: 10;
  cursor: help;
`;

const RightWenzel = styled.img`
  margin-left: -${WENZEL_MARGIN}px;
  cursor: crosshair;
`;

const Wenzels = () => {
  const wenzelsContainerRef = useRef(null);
  const leftWenzelRef = useRef(null);
  const rightWenzelRef = useRef(null);

  useEffect(() => {
    // Animate on scroll
    scroll(
      ({ y }) => {
        if (wenzelsContainerRef.current) {
          wenzelsContainerRef.current.style.transform = `translateY(${y.progress * 30}px)`;
        }
      }
    );

    // Fade in when in view
    inView(wenzelsContainerRef.current, ({ target }) => {
      animate(
        target,
        { opacity: [0, 1], x: [-300, 0] },
        { duration: 1 }
      );
    });

    // Hover animations
    if (leftWenzelRef.current) {
      leftWenzelRef.current.addEventListener('mouseenter', () => {
        animate(leftWenzelRef.current, { x: -50 }, { duration: 1 });
      });
    }

    if (rightWenzelRef.current) {
      rightWenzelRef.current.addEventListener('mouseenter', () => {
        animate(rightWenzelRef.current, { x: 100, rotate: 20 }, { duration: 1 });
      });
    }
  }, []);

  return (
    <WenzelsContainer ref={wenzelsContainerRef} className="wenzels-container h-80 col-span-2 z-10 flex-row justify-center col-start-2 col-end-3 absolute grid w-screen grid-cols-8 left-0">
      <div style={{
        gridColumnStart: 3,
        gridColumnEnd: 4,
      }}>
        <LeftWenzel ref={leftWenzelRef} className="left-wenzel" src={WenzelOutline} />
        <CenterWenzel src={WenzelFilled} />
        <RightWenzel ref={rightWenzelRef} className="right-wenzel" src={WenzelOutline} />
      </div>
    </WenzelsContainer>
  );
};

const Summary = () => {
  const containerRef = useRef(null);
  const titleRef = useRef(null);

  useEffect(() => {
    // Scroll animation for main container
    scroll(
      ({ y }) => {
        if (containerRef.current) {
          containerRef.current.style.transform = `translateY(${y.progress * -100}px)`;
        }
      }
    );

    // Hover animation for title
    if (titleRef.current) {
      titleRef.current.addEventListener('mouseenter', () => {
        animate(titleRef.current, { y: -10 }, { duration: 0.5 });
      });
    }
  }, []);

  return (
    <div ref={containerRef} className="summary-container relative z-10">
      <div className="p-12 pt-0 xl:p-0 w-full h-screen/3 xl:h-screen flex flex-col justify-center items-center mb-48">
        <div className="flex flex-col-reverse justify-end xl:grid xl:grid-rows-1 xl:grid-cols-2 w-full h-full flex-1">
          <div className="container opacity-0 xl:opacity-100 flex items-center justify-center">
            <Polygons />
            <Wenzels />
          </div>

          <div className="container flex items-center justify-center z-10">
            <div className="container">
              <WenzelTitle ref={titleRef} className="wenzel-title">
                wenzel
              </WenzelTitle>
              <div className="container my-7 max-w-xl text-right md:text-left">
                <p className="text-2xl uppercase">
                  {SUMMARY_TEXT}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Summary;
