import React, { useState, useCallback, useContext, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { animate, scroll, inView } from 'motion';
import { PlayCircle } from 'react-feather';
import { useInView } from 'react-intersection-observer';
import ThemeContext from '../context/ThemeContext';
import videographyData from './videographyData';

const DESCRIPTION = 'storytelling via motion pictures. previously have shot short films, events, weddings, and music videos.';

const VideoWrapper = styled.div.attrs({
  className: 'mb-44 z-50 isolate',
})`
  position: relative;
  padding-bottom: ${(props) => (props.sixteen ? 56.25 : 36.23)}%; /* 2.76:1 */
  height: 0;
  overflow: hidden;
  width: 100%;
`;

const IframeVideo = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const VideoIcon = styled.div.attrs({
  className: 'w-72 h-32 z-10',
})`
  background-image: url('${(props) => props.src}');
  background-size: cover;
  cursor: pointer;
`;

const VideoHoverTitle = styled.div.attrs({
  className: 'w-full h-full flex flex-col justify-center align-center text-sm bg-gray-700 bg-opacity-90 justify-center text-white opacity-0 hover:opacity-100 transition-opacity',
})``;

const VideoRow = ({
  image, name, desc, reverse, videoSrc, handleClickVideoSrc,
}) => {
  useEffect(() => {
    const videoIcon = document.querySelector(`.video-icon-${name.split(' ')[0].toLowerCase()}`);

    inView(videoIcon, ({ target }) => {
      animate(
        target,
        {
          opacity: [0, 1],
          x: [reverse ? 100 : -100, 0]
        },
        { duration: 1, easing: 'ease-in-out' }
      );

      const hr = target.nextElementSibling;
      animate(
        hr,
        {
          opacity: [0, 1],
          x: [reverse ? 300 : -300, 0]
        },
        { duration: 1.5, easing: 'ease-in-out', delay: 0.5 }
      );

      const content = hr.nextElementSibling;
      animate(
        content,
        { opacity: [0, 1] },
        { duration: 1.5, easing: 'ease-in-out', delay: 1 }
      );
    });

    videoIcon.addEventListener('mouseenter', () => {
      animate(videoIcon, { y: -10 }, { duration: 0.5 });
    });
  }, [reverse, name]);

  return (
    <div className={`flex flex-col xl:${reverse ? 'flex-row-reverse' : 'flex-row'} justify-center items-center mb-24`}>
      <VideoIcon
        src={image}
        className={`video-icon-${name.split(' ')[0].toLowerCase()}`}
        onClick={handleClickVideoSrc}
      >
        <VideoHoverTitle><PlayCircle className="self-center" /></VideoHoverTitle>
      </VideoIcon>
      <hr className="hidden xl:block w-64 border-dashed z-0" />
      <div className="mx-8">
        <h6 className={`text-2xl text-center xl:${reverse ? 'text-right' : 'text-left'} font-bold text-white uppercase mt-4 xl:mt-0`}>
          {name}
        </h6>
        <p className={`text-md xl:text-xl text-center xl:${reverse ? 'text-right' : 'text-left'} uppercase text-gray-400 max-w-lg`}>
          <br className="hidden xl:block" />
          {desc}
        </p>
      </div>
    </div>
  );
};

const FadeBox = styled.div`
  background-color: #000000;
  z-index: -10;
  &:before {
    content: "";
    background-color: #000000;
    position: absolute;
    left: 0;
    right: 0;
    height: 500px;
    top:-250px;
    transform: skewY(5deg)
  }
`;

const Videography = () => {
  const [videoData, setVideoData] = useState(videographyData.find((v) => v.default));
  const [inViewRef, inView] = useInView();
  const { dark: [, toggleDark] } = useContext(ThemeContext);
  const videoRef = useRef(null);
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);

  const setSrcAndScroll = (video) => {
    setVideoData(video);
    setIsVideoLoaded(true);
    videoRef.current.scrollIntoView();
  };

  useEffect(() => {
    if (inView) {
      toggleDark(true);
    } else {
      toggleDark(false);
    }
  }, [inView]);

  useEffect(() => {
    const title = document.querySelector('.videography-title');
    title.addEventListener('mouseenter', () => {
      animate(title, { y: -10 }, { duration: 0.5 });
    });
  }, []);

  // Optimize YouTube URL
  const getOptimizedYouTubeUrl = (url) => {
    if (!url) return '';
    // If it's a playlist URL, return as is
    if (url.includes('playlist')) return url;

    // For video URLs, add quality and performance parameters
    const match = url.match(/(?:youtube\.com\/(?:[^/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?/\s]{11})/);
    const videoId = match ? match[1] : null;
    if (!videoId) return url;

    return `https://www.youtube.com/embed/${videoId}?rel=0&modestbranding=1&autohide=1&showinfo=0&vq=hd720&playsinline=1&enablejsapi=1&origin=${window.location.origin}`;
  };

  return (
    <div ref={inViewRef} className="duration-1000 mt-96 relative opacity-100 ease-in-out w-full min-h-screen flex flex-col items-center pb-96" id="videography">
      <FadeBox className="bg-black absolute -mb-48 left-0 top-0 bottom-0 right-0 z-10" />
      <div className="flex flex-row w-full">
        <div className="container" />
        <div className="container flex z-10 mb-44">
          <div className="container p-12 xl:p-0">
            <h1 className="videography-title text-5xl lg:text-6xl xl:text-7.5xl font-display font-bold text-white">
              videographer
            </h1>
            <div className="container my-7 max-w-xl text-right md:text-left text-white">
              <p className="text-2xl uppercase">
                {DESCRIPTION}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col items-center w-full">
        {videographyData.map((video, idx) => (
          <VideoRow
            key={video.name}
            handleClickVideoSrc={video.newTab ? () => window.open(video.tabSrc, '_blank').focus() : () => setSrcAndScroll(video)}
            videoSrc={video.src}
            image={video.image}
            name={video.name}
            desc={video.desc}
            reverse={idx % 2 === 0}
          />
        ))}

        <VideoWrapper
          name="videoPlayer"
          id="videoPlayer"
          ref={videoRef}
          sixteen={!videoData.anamorphic}
        >
          {isVideoLoaded && (
            <IframeVideo
              height="696"
              width="1920"
              src={getOptimizedYouTubeUrl(videoData.src)}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              onLoad={() => setIsVideoLoaded(true)}
            />
          )}
        </VideoWrapper>
      </div>
    </div>
  );
};

export default Videography;
