import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeContextProvider } from './context/ThemeContext';
import Layout from './components/Layout';

import Header from './components/Header';
import HeroTitle from './components/HeroTitle';
import Summary from './components/Summary';
import Software from './components/Software';
import SocialLinks from './components/SocialLinks';
import Videography from './components/Videography';
import Footer from './components/Footer';

const App = () => {
  const scrollToRef = (ref) => {
    if (ref === 'software') {
      document.getElementById('software').scrollIntoView();
    } else if (ref === 'videography') {
      document.getElementById('videography').scrollIntoView();
    }
  };

  return (
    <ThemeContextProvider>
      <Layout />
      <main className="overflow-hidden z-10">
        <div className="z-10 w-full">
          <SocialLinks />
          <Header />
          <HeroTitle
            scrollToSoftware={() => scrollToRef('software')}
            scrollToVideography={() => scrollToRef('videography')}
          />
          <Summary />
          <Software />
          <Videography />
          <Footer />
        </div>
      </main>
    </ThemeContextProvider>
  );
};

export default App; 