import React, { forwardRef, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { animate, scroll, inView } from 'motion';
import Acknowledged from '../images/acknowledged.webp';
import Tend from '../images/tend.webp';
import Github from '../images/github.png';
import SWEShape from './shapes/SWEShape';

// const SWE_DESCRIPTION = 'here is full list of experiences. here are some of my public projects.';

const ImagePortfolioBox = styled.a.attrs({
  className: 'w-24 h-24 xl:w-44 xl:h-44 m-2 border-solid border border-gray-300',
})`
  background: url("${(props) => props.imageUrl}") no-repeat center center;
  background-size: contain;
  position: relative;
  &:before {
    content: "";
    border: dashed 1px gray;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0.5rem;
    left: 0.5rem;
    z-index: -5;
  }
`;

const ImagePortfolioHoverTitle = styled.div.attrs({
  className: 'w-full h-full flex flex-col justify-center align-center text-sm bg-gray-700 bg-opacity-90 text-center text-white opacity-0 hover:opacity-100 transition-opacity z-10',
})``;

const SWEPortfolio = () => {
  const portfolioRef = useRef(null);
  const portfolioItemsRef = useRef([]);

  useEffect(() => {
    inView(portfolioRef.current, ({ target }) => {
      portfolioItemsRef.current.forEach((item, index) => {
        if (item) {
          animate(
            item,
            { opacity: [0, 1], x: [100, 0] },
            { duration: 0.5, delay: index * 0.3 }
          );
        }
      });
    });

    portfolioItemsRef.current.forEach(item => {
      if (item) {
        item.addEventListener('mouseenter', () => {
          animate(item, { scale: 1.05, y: -10 }, { duration: 0.2 });
        });

        item.addEventListener('mouseleave', () => {
          animate(item, { scale: 1, y: 0 }, { duration: 0.2 });
        });
      }
    });
  }, []);

  return (
    <div ref={portfolioRef} className="portfolio-container flex flex-row justify-center xl:justify-start w-full z-10 mb-12 xl:mb-0">
      <ImagePortfolioBox
        ref={el => portfolioItemsRef.current[0] = el}
        className="portfolio-item"
        imageUrl={Acknowledged}
        href="https://github.com/lowewenzel/acknowledged-bot"
        target="_blank"
        rel="noopener noreferrer"
      >
        <ImagePortfolioHoverTitle>
          AcknowledgedBot
        </ImagePortfolioHoverTitle>
      </ImagePortfolioBox>
      <ImagePortfolioBox
        ref={el => portfolioItemsRef.current[1] = el}
        className="portfolio-item"
        imageUrl={Tend}
        href="https://tend.wnzl.dev"
        target="_blank"
        rel="noopener noreferrer"
      >
        <ImagePortfolioHoverTitle>
          Tend
        </ImagePortfolioHoverTitle>
      </ImagePortfolioBox>
      <ImagePortfolioBox
        ref={el => portfolioItemsRef.current[2] = el}
        className="portfolio-item"
        imageUrl={Github}
        href="https://github.com/lowewenzel"
        target="_blank"
        rel="noopener noreferrer"
      >
        <ImagePortfolioHoverTitle>
          Github
        </ImagePortfolioHoverTitle>
      </ImagePortfolioBox>
    </div>
  );
};

const StripedSWEPolygon = styled(SWEShape)`
  position: absolute;
  left: 40px;
  top: 40px;
`;

const SWEPolygons = () => {
  const polygonsRef = useRef(null);

  useEffect(() => {
    scroll(
      ({ y }) => {
        if (polygonsRef.current) {
          polygonsRef.current.style.transform = `translateX(${y.progress * 20 - 10}px)`;
        }
      }
    );
  }, []);

  return (
    <div ref={polygonsRef} className="swe-polygons z-0 absolute w-2/6 h-full right-0 mt-80 xl:mt-0 hidden xl:block">
      <SWEShape />
      <StripedSWEPolygon noFill />
    </div>
  );
};

const Software = (props, ref) => {
  const containerRef = useRef(null);
  const titleRef = useRef(null);

  useEffect(() => {
    scroll(
      ({ y }) => {
        if (containerRef.current) {
          containerRef.current.style.transform = `translateY(${y.progress * -50}px)`;
        }
      }
    );

    if (titleRef.current) {
      titleRef.current.addEventListener('mouseenter', () => {
        animate(titleRef.current, { y: -10 }, { duration: 0.5 });
      });
    }
  }, []);

  return (
    <div ref={(node) => {
      containerRef.current = node;
      if (typeof ref === 'function') ref(node);
      else if (ref) ref.current = node;
    }} className="software-container relative z-10">
      <div className="w-full h-screen/2 xl:h-screen flex flex-col justify-center items-center" id="software">
        <div className="flex flex-col items-center justify-end xl:grid xl:grid-cols-2 w-full h-full flex-1 -mt-64 xl:mt-0">
          <div className="w-full flex items-center justify-center z-10">
            <div className="container w-9/12 text-left">
              <h1 ref={titleRef} className="software-title text-5xl lg:text-6xl xl:text-7.5xl font-display font-bold">
                software engineer
              </h1>
              <div className="container my-7 max-w-xl">
                <p className="text-2xl uppercase">
                  experience in react, angular, nextjs, nodejs, java, and ruby on rails tech stacks.
                  check out my public projects:
                </p>
              </div>
            </div>
          </div>
          <div className="container flex items-center justify-center">
            <SWEPolygons />
            <SWEPortfolio />
          </div>
        </div>
      </div>
    </div>
  );
};

export default forwardRef(Software);
